<template>
  <div class="text-center">
    <h3 class="landingSubtitle pt-2">{{ $t("encuestas.titulo") }}</h3>

    <div class="py-9">
      <div class="row mb-6">
        <div class="label" style="white-space: pre-line">
          {{ $t("encuestas.yaContestado") }}
        </div>
      </div>
    </div>
  </div>
</template>
